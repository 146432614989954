export type SearchConfig = any;
export type SkuCardConfiguration = any;
export type ProductCardConfiguration = any;

const preFilter = {};
const GRID = "GRID";
const LISTING = "LISTING";
const LINEBYLINE = "LISTINGLINEBYLINE";
const viewModes = {
  LISTINGLINEBYLINE: { name: "frontend.bulkorder.linebyline" },
  GRID: { name: "frontend.bulkorder.grid" },
  LISTING: { name: "frontend.bulkorder.list" },
};
export { preFilter, GRID, LISTING, LINEBYLINE, viewModes };
