import { SearchSku } from "../../../interface/SearchProduct";
import { useElementContext } from "../../../contexts";

interface ProductCardImageProps {
  product: SearchSku;
}

const ProductCardImage = ({ product }: ProductCardImageProps) => {
  const {
    CommonModule: { ProductImage, SimpleImage },
  } = useElementContext();
  const {
    skuName,
    imagePath = "",
    imageFile,
    images = [],
    skuID = "",
    product_defaultSku_calculatedImageExists,
    calculatedImageExists,
    product_defaultSku_imageFile,
  } = product;

  const useResizedImage = images && images?.length > 0;
  let imageFilePath = imageFile;
  if (calculatedImageExists === true) {
    imageFilePath = imageFile;
  } else if (product_defaultSku_calculatedImageExists === true) {
    //fallback to default sku image
    imageFilePath = product_defaultSku_imageFile;
  } else {
    imageFilePath = imageFile;
  }

  return (
    <>
      {useResizedImage && <SimpleImage className="productCardImage" src={images?.at(0)} alt={skuName} type="product" />}
      {!useResizedImage && imagePath && (
        <ProductImage customClass="productCardImage" imageFile={imagePath} skuID={skuID} customPath="/" />
      )}
      {!useResizedImage && imageFilePath && (
        <ProductImage customClass="productCardImage" imageFile={imageFilePath} skuID={skuID} />
      )}
    </>
  );
};

export { ProductCardImage, type ProductCardImageProps };
