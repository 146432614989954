import { useCheckoutUtilities } from "../../../hooks";
import { useElementContext } from "../../../contexts";

const ExternalPaymentDetails = ({ payment, hideHeading }) => {
  const {
    CommonModule: { TillPaymentDetails },
  } = useElementContext();
  const { TILL_PAYMENTS_CODE } = useCheckoutUtilities();

  // Two possibilities for payment, getOrderDetails or payment from the Cart which are different.
  const integrationPackage =
    payment.paymentMethod_paymentIntegration_integrationPackage ||
    payment.paymentMethod.paymentIntegration.integrationPackage;
  const paymentMethodName = payment.paymentMethod_paymentMethodName || payment.paymentMethod.paymentMethodName;

  return (
    <>
      {(() => {
        switch (integrationPackage) {
          case TILL_PAYMENTS_CODE:
            return <TillPaymentDetails hideHeading={hideHeading} payment={payment} />;
          default:
            return (
              <p className="mb-1">
                <em>{paymentMethodName}</em>
                <br />
              </p>
            );
        }
      })()}
    </>
  );
};
export { ExternalPaymentDetails };
