import { useUtilities } from "../../../hooks/useUtilities";

const BasicCard = (props) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const {
    stylingCustomClasses = "",
    imagePosition = "top",
    contentElementID,
    contentCardImage,
    contentHeading = "",
    contentSubHeading = "",
    contentBody = "",
    bootStrapLayoutClass,
    customStyleClasses,
  } = props;
  const { linkLabel = "", linkUrl = "", urlTarget = "_self" } = props;
  return (
    <div
      className={[
        `card overflow-hidden swBasicCard align-items-center my-2 py-2 text-break ${
          bootStrapLayoutClass ? bootStrapLayoutClass : ""
        } ${customStyleClasses || ""}`,
        stylingCustomClasses,
      ].join(" ")}
      id={contentElementID}
    >
      <div>
        {imagePosition === "top" && contentCardImage?.trim()?.length > 0 && (
          <img src={contentCardImage} style={{ width: "100%" }} className="card-img-top align-self-center" alt="" />
        )}
      </div>
      <div className="card-body d-flex flex-column" onClick={eventHandlerForWSIWYG}>
        <div className="flex-grow-1 w-100">
          {contentHeading?.trim()?.length > 0 && (
            <h5 className="card-title text-uppercase font-weight-bold">{contentHeading}</h5>
          )}
          {contentSubHeading?.trim()?.length > 0 && (
            <h6 className="card-subtitle font-weight-bold mb-2 text-muted">{contentSubHeading}</h6>
          )}
          {contentBody?.trim()?.length > 0 && (
            <div className="card-text" dangerouslySetInnerHTML={{ __html: contentBody }} />
          )}
        </div>
        {linkLabel?.trim()?.length > 0 && (
          <a
            className="btn btn-primary mt-4 d-block mx-auto"
            style={{ width: "fit-content" }}
            rel="noreferrer"
            href={linkUrl}
            target={urlTarget}
          >
            {linkLabel}
          </a>
        )}
      </div>
      <div>
        {imagePosition === "bottom" && contentCardImage?.trim()?.length > 0 && (
          <img src={contentCardImage} className="card-img-bottom align-self-center" alt="" />
        )}
      </div>
    </div>
  );
};

const HorizontalCard = (props) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const {
    imagePosition = "left",
    contentElementID,
    contentCardImage,
    contentHeading = "",
    contentSubHeading = "",
    contentBody = "",
    customStyleClasses,
  } = props;
  const { linkLabel = "", linkUrl = "", urlTarget = "_self" } = props;

  return (
    <div className={`card overflow-hidden ${customStyleClasses || ""}`} id={contentElementID}>
      <div className="row g-0" style={{ flex: "1" }}>
        {imagePosition === "left" && (
          <div className="col-md-4 d-flex">
            {contentCardImage?.trim()?.length > 0 && (
              <img src={contentCardImage} className="img-fluid" style={{ objectFit: "cover" }} alt="" />
            )}
          </div>
        )}
        <div className="col-md-8">
          <div className="card-body" onClick={eventHandlerForWSIWYG}>
            {contentHeading?.trim()?.length > 0 && (
              <h5 className="card-title text-uppercase font-weight-bold">{contentHeading}</h5>
            )}
            {contentSubHeading?.trim()?.length > 0 && (
              <h6 className="card-subtitle font-weight-bold mb-2">{contentSubHeading}</h6>
            )}
            {contentBody?.trim()?.length > 0 && (
              <div
                onClick={eventHandlerForWSIWYG}
                className="card-text"
                dangerouslySetInnerHTML={{ __html: contentBody }}
              />
            )}
            {linkLabel?.trim()?.length > 0 && (
              <a className="btn btn-primary w-100 mt-4" rel="noreferrer" href={linkUrl} target={urlTarget}>
                {linkLabel}
              </a>
            )}
          </div>
        </div>
        {imagePosition === "right" && (
          <div className="col-md-4 d-flex">
            {contentCardImage?.trim()?.length > 0 && (
              <img src={contentCardImage} className="img-fluid" style={{ objectFit: "cover" }} alt="" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const OverlayCard = (props) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const {
    overlayType = "left",
    contentElementID,
    contentCardImage,
    contentHeading = "",
    contentSubHeading = "",
    contentBody = "",
    customStyleClasses,
  } = props;
  const { linkLabel = "", linkUrl = "", urlTarget } = props;

  return (
    <div className={`card overflow-hidden card-${overlayType} ${customStyleClasses || ""}`} id={contentElementID}>
      <div className="card-img-container">
        {contentCardImage?.trim()?.length > 0 && <img src={contentCardImage} className="card-img-overlay" alt="" />}
      </div>
      <div
        className={`px-4 card-overlay card-overlay-${overlayType} ${overlayType === "heading" && "p-4"}`}
        onClick={eventHandlerForWSIWYG}
      >
        {contentHeading?.trim()?.length > 0 && (
          <h5 className="card-title text-uppercase font-weight-bold">{contentHeading}</h5>
        )}
        {contentSubHeading?.trim()?.length > 0 && (
          <h6 className="card-subtitle font-weight-bold mb-2">{contentSubHeading}</h6>
        )}
        {contentBody?.trim()?.length > 0 && (
          <div className="card-text" dangerouslySetInnerHTML={{ __html: contentBody }} />
        )}
        {linkLabel?.trim()?.length > 0 && (
          <a className="btn btn-primary w-100 mt-4" rel="noreferrer" href={linkUrl} target={urlTarget}>
            {linkLabel}
          </a>
        )}
      </div>
    </div>
  );
};

export { BasicCard, OverlayCard, HorizontalCard };
