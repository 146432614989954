import { ReactNode } from "react";
import ContentLoader from "react-content-loader";

export interface ListingGridLoaderProps {
  cards?: number;
  cardSize?: string;
  Card?: ReactNode;
}

const ListingGridLoader = ({ cards = 9, cardSize = "250px" }) => (
  <div className="d-flex gap-5 flex-wrap px-4">
    {Array.apply(null, new Array(cards)).map((_, i) => (
      <ContentLoader
        key={i}
        viewBox="0 0 300 400"
        uniqueKey={`listingGridLoader_${i}`}
        className="listingGridLoader"
        style={{ width: cardSize, flexGrow: 1 }}
      >
        <rect y="0" rx="8" ry="8" width="300" height="300" />
        <rect y="320" rx="0" ry="0" width="300" height="32" />
        <rect y="380" rx="0" ry="0" width="180" height="36" />
      </ContentLoader>
    ))}
  </div>
);

export { ListingGridLoader };
