import { useMemo, useState } from "react";
import { useElementContext } from "../../../contexts";
import { Product, Sku } from "../../../interface/Product";
import { parseSkuPriceModifiers } from "../../../utils";
import { SearchSku } from "../../../interface/SearchProduct";

export interface ProductModifiersModalProps {
  sku?: Sku | SearchSku;
  initialSkuConfiguration?: { [key: string]: string };
  product?: Product;
  setShow: (open: boolean) => void;
  addItem?: ({ skuConfiguration }: { skuConfiguration: { [key: string]: string } }) => void;
}

const ProductModifiersModal = ({
  sku,
  product,
  setShow,
  addItem,
  initialSkuConfiguration = {},
}: ProductModifiersModalProps) => {
  const defaultSku = sku || product?.skus.find(({ skuID }) => skuID === product.defaultSku_skuID) || product?.skus[0];
  const [skuConfiguration, setSkuConfiguration] = useState<{ [key: string]: string }>(initialSkuConfiguration);
  const modifiers = parseSkuPriceModifiers(defaultSku?.priceGroupModifiers);
  const showAddItem = useMemo(
    () => modifiers?.every((modifier) => !modifier.required || skuConfiguration[modifier.key]),
    [modifiers, skuConfiguration],
  );
  const {
    CommonModule: { Modal, Portal },
    ProductModule,
  } = useElementContext();

  return (
    <Portal>
      <Modal setShow={setShow} title="Select product configuration">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addItem?.({ skuConfiguration });
          }}
        >
          <h3 className="p-0">{defaultSku?.skuName || defaultSku?.product_productName || product?.productName}</h3>
          <ProductModule.CoreControl.ProductModifiers
            modifiers={modifiers || []}
            setSkuConfiguration={setSkuConfiguration}
            skuConfiguration={skuConfiguration}
          />
          {showAddItem && (
            <button type="submit" className="btn btn-primary">
              Add Item
            </button>
          )}
        </form>
      </Modal>
    </Portal>
  );
};

export { ProductModifiersModal };
