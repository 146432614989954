import { useElementContext } from "../../../contexts";

const FormCheckboxField = (props) => {
  const {
    CommonModule: { FormCheckboxGroupField },
  } = useElementContext();
  return <FormCheckboxGroupField {...props} options={[{ value: true, name: props.attributeName }]} />;
};

export default FormCheckboxField;
