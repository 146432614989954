import { addToCartOrQuote } from "../../../../actions";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductCardActionsProps } from "./ProductCardActions.componentModel";
import { ProductCardActionsModel } from "./ProductCardActions.componentModel";
import { isAuthenticated } from "../../../../utils";

export function useComponentModel(props: ProductCardActionsProps) {
  const dispatch = useDispatch();
  const [isFetching, setFetching] = useState(false);
  const [itemCount, setItemCount] = useState(1);
  const [showProductModifierModifier, setProductModifierModal] = useState(false);
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);

  const componentData = new ProductCardActionsModel({
    props,
    sitePurpose: siteSetting.sitePurpose,
    isAuthenticatedUser: isAuthenticated(),
    isFetching,
    itemCount,
    showProductModifierModifier,
  });

  const onAddToCart = useCallback(
    async (itemCount: number, skuConfiguration?: { [key: string]: string }) => {
      if (props.sku.priceGroupModifiers?.length && !skuConfiguration) {
        setProductModifierModal(true);
        return;
      }
      setProductModifierModal(false);
      setFetching(true);
      await dispatch(addToCartOrQuote(props.sku, itemCount, undefined, undefined, skuConfiguration) as any);
      setFetching(false);
      setItemCount(1);
    },
    [dispatch, props.sku],
  );

  const setQuantity = (n: number) => {
    setItemCount(n);
  };

  return { componentData, componentService: { onAddToCart, setQuantity, setProductModifierModal } };
}
