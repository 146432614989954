import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProductRoute } from "../../../selectors";
import { SearchSku } from "../../../interface/SearchProduct";
import { Sku } from "../../../interface/Product";
import { useElementContext } from "../../../contexts";
import { isAuthenticated } from "../../../utils";

interface ProductCardButtonProps {
  sku: SearchSku | Sku;
  isFetching: boolean;
  onAddToCart: () => void;
  listingButtonLabel: string;
  buttonStyle: "btn-primary" | "btn-link";
  disableListingButton?: boolean;
  type?: string;
  showAuthenticationRequiredMessageFlag?: boolean;
}

const AuthenticateRequiredButton = ({
  buttonStyle,
  listingButtonLabel,
  disableListingButton,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const {
    CommonModule: { Button, LoginRequiredModal },
  } = useElementContext();
  return (
    <>
      <Button
        classList={`${getButtonStyle(buttonStyle)}`}
        onClick={(e) => {
          e.preventDefault();
          setShowAuthPopup(true);
        }}
        disabled={disableListingButton}
      >
        {listingButtonLabel}
      </Button>

      {showAuthPopup && (
        <LoginRequiredModal show={showAuthPopup} setShow={setShowAuthPopup} title={listingButtonLabel} />
      )}
    </>
  );
};

const getButtonStyle = (buttonStyle: ProductCardButtonProps["buttonStyle"] = "btn-primary") => {
  switch (buttonStyle) {
    case "btn-primary":
      return "btn btn-primary";
    case "btn-link":
      return "btn btn-sm btn-link text-primary text-decoration-none fw-normal p-0";
    default:
      return buttonStyle;
  }
};

const QuoteButton = ({
  buttonStyle,
  isFetching,
  sku,
  listingButtonLabel,
  disableListingButton,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const skuConfigurationRef = useRef<{ [key: string]: string }>();
  const [modifierModal, setModifierModal] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const {
    ProductModule,
    CommonModule: { Button },
  } = useElementContext();
  return (
    <>
      <Button
        isLoading={isFetching}
        classList={`${getButtonStyle(buttonStyle)}`}
        onClick={(e) => {
          e.preventDefault();
          skuConfigurationRef.current = undefined;
          if (sku.priceGroupModifiers?.length) {
            setModifierModal(true);
            return;
          }
          setQuoteModal(true);
        }}
        disabled={isFetching || disableListingButton}
      >
        {listingButtonLabel}
      </Button>

      {modifierModal && (
        <ProductModule.CoreControl.ProductModifiersModal
          sku={sku}
          setShow={setModifierModal}
          addItem={({ skuConfiguration }) => {
            skuConfigurationRef.current = skuConfiguration;
            setQuoteModal(true);
            setModifierModal(false);
          }}
        />
      )}

      {quoteModal && (
        <ProductModule.CoreComponents.AddProductToQuoteModal
          skuID={sku.skuID || ""}
          quantity={1}
          setQuoteModal={setQuoteModal}
          skuConfiguration={skuConfigurationRef.current}
        />
      )}
    </>
  );
};

const ListButton = ({
  isFetching,
  sku,
  listingButtonLabel,
  disableListingButton,
  buttonStyle,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const [listModal, setListModal] = useState(false);
  const {
    ProductModule,
    CommonModule: { Button },
  } = useElementContext();
  return (
    <>
      <Button
        isLoading={isFetching}
        classList={`${getButtonStyle(buttonStyle)}`}
        onClick={(e) => {
          e.preventDefault();
          setListModal(true);
        }}
        disabled={isFetching || disableListingButton}
      >
        {listingButtonLabel}
      </Button>
      {listModal && <ProductModule.CoreComponents.AddProductToListModal sku={sku} setListModal={setListModal} />}
    </>
  );
};

const ViewButton = ({
  sku,
  listingButtonLabel,
  buttonStyle,
  disableListingButton = false,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const productUrl = useSelector(getProductRoute) as string;
  const productLink = `/${productUrl}/${sku.product_urlTitle}` + (sku.skuID?.length ? `?skuid=${sku.skuID}` : "");

  return (
    <Link
      to={productLink}
      aria-disabled={disableListingButton}
      className={`${getButtonStyle(buttonStyle)} ${disableListingButton ? "pe-none disabled" : ""}`}
    >
      {listingButtonLabel}
    </Link>
  );
};

const AddToCardButton = ({
  isFetching,
  onAddToCart,
  disableListingButton = false,
  listingButtonLabel,
  sku,
  buttonStyle,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const {
    CommonModule: { Button },
  } = useElementContext();
  return (
    <Button
      disabled={
        isFetching ||
        disableListingButton ||
        !sku?.settings?.skuAllowAddToCartFlag ||
        (!sku?.salePrice && !sku?.listPrice)
      }
      isLoading={isFetching}
      classList={`${getButtonStyle(buttonStyle)}`}
      onClick={(e) => {
        e.preventDefault();
        onAddToCart();
      }}
    >
      {listingButtonLabel}
    </Button>
  );
};

const FileButton = ({
  buttonStyle,
  disableListingButton = false,
}: Omit<ProductCardButtonProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  return (
    <Link
      to="link-to-file"
      className={`${getButtonStyle(buttonStyle)} ${disableListingButton ? "pe-none disabled" : ""}`}
      aria-disabled={disableListingButton}
    >
      Link to File
    </Link>
  );
};

const ProductCardButton = ({
  type = "ADD_TO_CART",
  showAuthenticationRequiredMessageFlag = false,
  ...rest
}: ProductCardButtonProps) => {
  if (!isAuthenticated() && showAuthenticationRequiredMessageFlag) return <AuthenticateRequiredButton {...rest} />;

  if (type === "VIEW") return <ViewButton {...rest} />;
  if (type === "ADD_TO_CART") return <AddToCardButton {...rest} />;
  if (type === "ADD_TO_QUOTE") return <QuoteButton {...rest} />;
  if (type === "ADD_TO_LIST") return <ListButton {...rest} />;
  if (type === "PRODUCT_ATTRIBUTE_FILE") return <FileButton {...rest} />;
  return null;
};

export {
  ProductCardButton,
  type ProductCardButtonProps,
  AuthenticateRequiredButton,
  ViewButton,
  AddToCardButton,
  QuoteButton,
  ListButton,
  FileButton,
};
