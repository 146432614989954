import { useElementContext } from "../../../contexts";

const useSocialLinks = (siteSetting, color, text = true) => {
  const {
    CommonModule: { SocialLinks },
  } = useElementContext();

  const Social = () => {
    return <SocialLinks color={color} prefix={text ? "Find Us On: " : ""} />;
  };

  return { Social };
};

export { useSocialLinks };
