import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDeepCompareEffect } from "react-use";
import { useMemo, useRef, useState } from "react";
import isEqual from "fast-deep-equal";
import { processQueryParameters } from "../../../utils";
import { axios } from "../../../services";
import { useServiceContext } from "../../../contexts";

const buildPath = (params) => {
  return queryString.stringify(params, { arrayFormat: "comma" });
};

const paramsIncludesForcedFilter = (searchConfig, params) => {
  if (searchConfig?.forcedFilterOptions?.length) {
    let resetFilters = false;
    Object.keys(params).forEach((param) => {
      const [, paramType] = param.split("_");

      // test for exact match brand_slug
      if (searchConfig.forcedFilterOptions.includes(param) && params[param]?.length) resetFilters = true;

      // test for for forced prefix like brand
      searchConfig.forcedFilterOptions.forEach((ffo) => {
        if (ffo.startsWith(paramType) && params[param]?.length) resetFilters = true;
      });
      return param;
    });
    return resetFilters;
  }
  return true;
};

const useListing = (preFilter, searchConfig) => {
  let [isFetching, setFetching] = useState(true);
  let [records, setRecords] = useState([]);
  let [searchMeta] = useState([]);
  let [total, setTotal] = useState(0);
  let [pageSize, setPageSize] = useState(12);
  let [totalPages, setTotalPages] = useState(1);
  let [potentialFilters, setPotentialFilters] = useState({});
  let [sortBy, setSortBy] = useState([]);
  let [error, setError] = useState({ isError: false, message: "" });
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  const loc = useLocation();
  let initialData = searchConfig.filters;

  const navigate = useNavigate();
  let params = processQueryParameters(loc.search);
  params = { ...initialData, ...params, ...preFilter };

  const documentType = searchConfig.params.productsListingFlag ? "product" : "sku";

  const isInitSearchRef = useRef(true);
  const skipSearchRef = useRef(false);
  useDeepCompareEffect(() => {
    if (searchConfig.searchCustomise) {
      var newParams = { ...params };
      if (isInitSearchRef.current) {
        isInitSearchRef.current = false;
        const serachPreFilter =
          searchConfig.searchCustomise?.preFilters?.reduce((acc, cur) => {
            if (acc[cur.slug]) acc[cur.slug].push(cur.value);
            else acc[cur.slug] = [cur.value];
            return acc;
          }, {}) || {};
        newParams = { ...serachPreFilter, ...params };
      }
      // Apply default sort when sort and keyword is undefined
      if (!newParams.sort && !newParams.keyword && searchConfig.searchCustomise?.defaultSort) {
        newParams = { ...newParams, sort: searchConfig.searchCustomise.defaultSort };
      }
      if (!isEqual(newParams, params)) {
        navigate(
          {
            pathname: loc.pathname,
            search: buildPath(newParams),
          },
          { replace: true },
        );
        return;
      }
    }

    if (skipSearchRef.current) {
      skipSearchRef.current = false;
      return;
    }
    let source = axios.CancelToken.source();
    setFetching(true);

    productService
      .search(params, documentType, source)
      .then((data) => {
        setRecords(data.products);
        setPotentialFilters(data.potentialFilters || {});
        setSortBy(data.sortBy || []);
        setTotal(data.resultCount);
        setTotalPages(Math.ceil(data.resultCount / data.pageSize));
        setError({ isError: false, message: "" });
        if (!!data.resultCount) {
          const newParms = { ...params };
          Object.keys(newParms).forEach((key) => {
            if (!key.startsWith("facet_") || !Array.isArray(params[key])) return;
            const filterSlug = key.replace("facet_", "");
            newParms[key] = newParms[key].reduce((acc, cur) => {
              if (data.potentialFilters[filterSlug]?.options?.some((option) => option.slug === cur)) {
                acc.push(cur);
              }
              return acc;
            }, []);
          });
          if (!isEqual(newParms, params)) {
            skipSearchRef.current = true;
            navigate(
              {
                pathname: loc.pathname,
                search: buildPath(newParms),
              },
              { replace: true },
            );
          }
        }
      })
      .catch(() => {
        setRecords([]);
        setPotentialFilters({});
        setTotal(0);
        setPageSize(12);
        setTotalPages(1);
        setError({ isError: true, message: "Something was wrong" });
      })
      .finally(() => {
        setFetching(false);
      });

    return () => {
      source.cancel();
    };
  }, [params, documentType]);

  const setPage = (pageNumber) => {
    params["currentPage"] = pageNumber;
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };
  const setKeyword = (keyword) => {
    params = {
      ...initialData,
      ...preFilter,
      orderBy: params.orderBy,
      keyword: keyword,
    };
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };
  const setSort = (sort) => {
    params["sort"] = sort;
    params["currentPage"] = 1;
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };

  const updateAttribute = (attribute) => {
    let attributeFilters = params[attribute.filterName] || [];
    if (typeof attributeFilters === "string") attributeFilters = [attributeFilters];
    if (attributeFilters.includes(attribute.name)) {
      attributeFilters = attributeFilters.filter((item) => item !== attribute.name);
    } else {
      if (attribute.selectType === "single") {
        attributeFilters = [attribute.name];
      } else {
        attributeFilters.push(attribute.name);
      }
    }

    params[attribute.filterName] = attributeFilters;
    params["currentPage"] = 1;

    if (!paramsIncludesForcedFilter(searchConfig, params)) {
      params = {
        ...initialData,
        ...preFilter,
        orderBy: params.orderBy,
        keyword: params.keyword,
      };
    }
    navigate({
      pathname: loc.pathname,
      search: buildPath(params),
    });
  };

  return {
    records,
    pageSize,
    potentialFilters,
    isFetching,
    total,
    totalPages,
    error,
    sortBy,
    setSort,
    updateAttribute,
    setPage,
    setKeyword,
    params,
    searchMeta,
  };
};

export { useListing };
