//COMPONENTS
import { FacetFilter } from "./Components/FacetFilter/FacetFilter.component";
import { SearchListing } from "./Components/SearchListing/SearchListing.component";

//CONTROLS
import { ListingSidebar } from "./Controls/ListingSidebar";
import { SearchPreFilter } from "./Controls/SearchPreFilter";
import { ListingGridView } from "./Controls/ListingGridView";
import { ListingListView } from "./Controls/ListingListView";
import { AttributeList } from "./Controls/AttributeList";
import { NoProductFound } from "./Controls/NoProductFound";
import { ListingToolBar } from "./Controls/ListingToolBar";
import { ListingViewToggle } from "./Controls/ListingViewToggle";

//CUSTOM

export namespace ProductListingModule {
  export const CoreComponents = {
    SearchListing,
    FacetFilter,
  };

  export const CoreControl = {
    AttributeList,
    ListingSidebar,
    ListingListView,
    ListingGridView,
    ListingToolBar,
    ListingViewToggle,
    SearchPreFilter,
    NoProductFound,
  };

  export const Custom = {};
}
