import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLoginForm } from "../../../hooks";
import { getSocialLogins } from "../../../selectors";
import { useElementContext } from "../../../contexts";

const LoginForm = ({ isCheckout = false }) => {
  const {
    CommonModule: { OauthGoogleLogin, OauthFacebookLogin, Oauth0Login, Button },
  } = useElementContext();
  const { t } = useTranslation();
  const { formik } = useLoginForm();
  const location = useLocation();
  const allowGuestCheckout = useSelector((state) => state.configuration.allowGuestCheckout);
  const socialLogins = useSelector(getSocialLogins);

  return (
    <>
      <div className="container col-lg-6 col-md-9 col-sm-12 pt-4">
        <div className="text-center">
          <h1>{t("frontend.account.myAccount")}</h1>
        </div>
        <hr />
        <h2>{t("frontend.account.sign_in")}</h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="loginEmail">{t("frontend.account.email")}</label>
              <input
                value={formik.values.loginEmail}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoComplete="email"
                className="form-control"
                type="email"
                id="loginEmail"
              />
              {formik.errors.loginEmail && <span className="form-error-msg">{formik.errors.loginEmail}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="loginPassword">{t("frontend.account.password")}</label>
              <input
                value={formik.values.loginPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoComplete="current-password"
                className="form-control"
                type="password"
                id="loginPassword"
              />
              {formik.errors.loginPassword && formik.touched.loginPassword && (
                <span className="form-error-msg">{formik.errors.loginPassword}</span>
              )}
            </div>
            <Link to={`/my-account/forgotPassword`} className="nav-link-inline font-size-sm link">
              {t("frontend.account.forgot_password")}
            </Link>
          </div>
          <div className="row my-2">
            <p>
              {t("frontend.account.no_account")}
              <Link className="link mx-1" to={`/register${location.search}`}>
                {t("frontend.account.here")}
              </Link>
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-2">
              <Button
                classList="btn btn-primary btn-lg w-100"
                type="submit"
                disabled={formik.isSubmitting}
                isLoading={formik.isSubmitting}
              >
                {t("frontend.account.sign_in")}
              </Button>

              {socialLogins?.map((integration) => {
                if (integration.key === "googlelogin") return <OauthGoogleLogin key={integration.key} />;
                if (integration.key === "auth0")
                  return <Oauth0Login key={integration.key} buttonText="Login With Auth0" />;
                if (integration.key === "facebooklogin")
                  return <OauthFacebookLogin key={integration.key} buttonText={t("frontend.oauth.facebooksignin")} />;
                return null;
              })}
            </div>

            {allowGuestCheckout && isCheckout && (
              <div className="col-lg-6 col-sm-12">
                <Link className="btn btn-primary btn-lg w-100" to={`/checkout/createGuestAccount${location.search}`}>
                  {t("frontend.account.checkout.as.guest")}
                </Link>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

const AccountLogin = ({ isCheckout = false }) => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <LoginForm isCheckout={isCheckout} />
      </div>
    </div>
  );
};

export { AccountLogin };
