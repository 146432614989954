import { Dispatch, SetStateAction } from "react";
import isEqual from "fast-deep-equal";
import { ProductModifier } from "../../../interface/Product";
import { useDeepCompareEffect } from "react-use";

export interface ProductModifiersProps {
  modifiers: ProductModifier[];
  skuConfiguration: { [key: string]: string };
  setSkuConfiguration: Dispatch<SetStateAction<ProductModifiersProps["skuConfiguration"]>>;
}

export const ProductModifiers = ({ modifiers, skuConfiguration, setSkuConfiguration }: ProductModifiersProps) => {
  useDeepCompareEffect(() => {
    setSkuConfiguration((prevState) => {
      const defaultValue: { [key: string]: string } = {};
      modifiers.forEach((modifier) => {
        if (prevState[modifier.key] && modifier.options.find(({ code }) => code === prevState[modifier.key])) {
          defaultValue[modifier.key] = prevState[modifier.key];
        }
        if (modifier.options.length === 1) {
          defaultValue[modifier.key] = modifier.options[0].code;
        }
      });
      if (!isEqual(prevState, defaultValue)) return defaultValue;
      return prevState;
    });
  }, [modifiers, setSkuConfiguration]);

  return (
    <div className="d-flex">
      <div>
        {modifiers?.map((modifier) => (
          <div className="my-4" key={modifier.key}>
            <label className="h6 pb-1">{modifier.title}</label>
            <select
              className="custom-select rounded-pill"
              value={skuConfiguration[modifier.key]}
              onChange={(e) => {
                setSkuConfiguration((prevState) => ({
                  ...prevState,
                  [modifier.key]: e.target.value,
                }));
              }}
            >
              {modifier.options.length > 1 ? <option value="">-- Choose Configuration Setting --</option> : null}
              {modifier.options.map((value) => (
                <option key={value.code} value={value.code}>
                  {value.title}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
    </div>
  );
};
