import Slider from "react-slick";
import { useGetProductsByEntityModified } from "../../hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useElementContext } from "../../contexts/ElementContext";
import { sorting } from "../../utils";

const ProductSlider = ({
  cardConfiguration,
  children,
  settings,
  title,
  slidesToShow = 4,
  products = [],
  customStyleClasses,
}) => {
  const { ProductCardModule } = useElementContext();
  settings = settings
    ? settings
    : {
        dots: false,
        infinite: products.length >= slidesToShow,
        // infinite: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <section className={`py-4 product-slider-sec ${customStyleClasses || ""}`}>
      <div className="container">
        <header className="section-title">
          <h2>{title}</h2>
        </header>
        <div className="card border-0 bg-transparent">
          {children}
          <Slider {...settings}>
            {products?.map((slide, index) => {
              return (
                <div className="repeater" key={index}>
                  {/*Fixed the slider design issue */}
                  <div className="card-body h-100">
                    <ProductCardModule.CoreControl.SkuCard {...slide} cardConfiguration={cardConfiguration} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

const ProductSliderWithList = ({ children, params = {}, settings, title, slidesToShow, productList = [] }) => {
  let [request, setRequest] = useGetProductsByEntityModified();

  useEffect(() => {
    let didCancel = false;
    if (!didCancel && !request.isFetching && !request.isLoaded && productList.length) {
      setRequest({
        ...request,
        params: {
          ...params,
          includeImages: true,
          "f:ProductID:in": productList.join(","),
        },
        entity: "product",
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [request, setRequest, params, productList]);
  if (!request.data.length || productList.length === 0) {
    return null;
  }
  const sortedProducts = sorting(request.data, productList, "productCode");

  return (
    <ProductSlider products={sortedProducts} settings={settings} title={title} slidesToShow={slidesToShow}>
      {children}
    </ProductSlider>
  );
};

const ProductSliderWithConfig = ({ children, params = {}, settings, title, slidesToShow }) => {
  let [request, setRequest] = useGetProductsByEntityModified();
  const { t } = useTranslation();
  useEffect(() => {
    let didCancel = false;
    if (!didCancel && !request.isFetching && !request.isLoaded) {
      setRequest({
        ...request,
        params: { includeImages: true, ...params },
        entity: "product",
        makeRequest: true,
        isFetching: true,
        isLoaded: false,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [request, setRequest, params]);
  if (!request.data.length) return null;

  return (
    <ProductSlider
      products={request.data}
      settings={settings}
      title={title || t("frontend.home.popular_products")}
      slidesToShow={slidesToShow}
    >
      {children}
    </ProductSlider>
  );
};

export { ProductSlider, ProductSliderWithConfig, ProductSliderWithList };
