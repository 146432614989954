import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentToOrder } from "../../../actions";
import { useTranslation } from "react-i18next";
import { getSavedCreditCardMethods } from "../../../selectors";
import { useElementContext } from "../../../contexts";

const CreditCardPayment = ({
  isEdit = false,
  method,
  fulfillment,
  isQuote = false,
  orderID,
  updateQuote,
  resetPaymentDetailsSelection = () => {},
}) => {
  const {
    CommonModule: { SwRadioSelect, CreditCardDetails },
  } = useElementContext();
  const paymentMethods = useSelector(getSavedCreditCardMethods);
  const [newOrderPayment, setNewOrderPayment] = useState(false);
  const { accountPaymentMethod = { accountPaymentMethodID: "" } } = method;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartState = useSelector((state) => state.cart);
  useEffect(() => {
    if (paymentMethods.length === 0) {
      // if there is no payment method found for the user , open new payment form
      setNewOrderPayment("new");
    }
    if (isEdit) {
      setNewOrderPayment("edit");
    }
  }, [paymentMethods, isEdit]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12">
          <hr />
          {!!newOrderPayment ? (
            <label className="w-100 h4 pt-0">{t("frontend.checkout.cardInfo")}</label>
          ) : (
            <SwRadioSelect
              label={t("frontend.checkout.cardInfo")}
              options={paymentMethods}
              onChange={(value) => {
                if (value === "new") {
                  setNewOrderPayment("new");
                } else {
                  setNewOrderPayment(false);
                  let params = {
                    accountPaymentMethodID: value,
                    orderPaymentID: cartState.orderPayments.at(0)?.orderPaymentID,
                  };
                  if (isQuote) params["orderID"] = orderID;
                  dispatch(addPaymentToOrder({ params, isQuote })).then((response) => {
                    resetPaymentDetailsSelection();
                    if (response.isSuccess() && isQuote && response.success().quote)
                      updateQuote(response.success().quote);
                  });
                }
              }}
              selectedValue={
                newOrderPayment
                  ? newOrderPayment
                  : cartState?.orderPayments.length === 0
                  ? accountPaymentMethod.accountPaymentMethodID
                  : cartState.orderPayments.at(0)?.accountPaymentMethod?.accountPaymentMethodID
              }
              displayNew={true}
            />
          )}

          {!newOrderPayment && (
            <button
              className="btn btn-secondary mt-2"
              onClick={() => {
                setNewOrderPayment("new");
              }}
            >
              {t("frontend.checkout.payment.add")}
            </button>
          )}
        </div>
      </div>
      {newOrderPayment && (
        <CreditCardDetails
          isEdit={newOrderPayment === "edit"}
          fulfillment={fulfillment}
          paymentMethodID={method}
          isQuote={isQuote}
          orderID={orderID}
          onSubmit={(res) => {
            if (Object.keys(res.success()?.errors || {}).length === 0) {
              setNewOrderPayment(false);
              resetPaymentDetailsSelection();
            }
          }}
          setNewOrderPayment={setNewOrderPayment}
        />
      )}
    </>
  );
};

export { CreditCardPayment };
