import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";

import { SearchConfig } from "../productListing.moduleModels";
import { PotentialFilters } from "../../../interface/SearchProduct";
import { useElementContext } from "../../../contexts";

const FilterLoader = () => (
  <ContentLoader
    uniqueKey="listingSidebarLoader"
    speed={2}
    viewBox="0 0 400 200"
    className="listingSidebarLoader d-lg-block d-none"
  >
    <rect x="25" y="15" rx="5" ry="5" width="350" height="20" />
    <rect x="25" y="45" rx="5" ry="5" width="350" height="10" />
    <rect x="25" y="60" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="75" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="107" rx="5" ry="5" width="350" height="20" />
    <rect x="26" y="135" rx="5" ry="5" width="350" height="10" />
    <rect x="26" y="150" rx="5" ry="5" width="350" height="10" />
    <rect x="27" y="165" rx="5" ry="5" width="350" height="10" />
  </ContentLoader>
);

export interface ListingSidebarProps {
  config: SearchConfig;
  isFetching: boolean;
  hide: string;
  filtering: PotentialFilters;
  recordsCount: number;
  updateAttribute: (data: { name: string; filterName: string }) => void;
}

export const ListingSidebar = ({
  config,
  isFetching,
  hide,
  filtering,
  updateAttribute,
  recordsCount,
}: ListingSidebarProps) => {
  const { t } = useTranslation();
  const {
    ProductListingModule,
    CommonModule: { SearchBar },
  } = useElementContext();
  const filters = useMemo(
    () =>
      Object.entries(filtering)
        .map(([type, filter]) => filter)
        .filter((filter) => filter.options.length && !hide.includes(filter.slug))
        .filter(
          (filter) =>
            !config.searchCustomise?.facets ||
            config.searchCustomise.facets.facetsToShow?.split(",").includes(filter.slug),
        )
        .sort((a, b) => a.sortOrder - b.sortOrder),
    // eslint-disable-next-line
    [filtering, hide, config.searchCustomise?.facetsToShow],
  );

  const FiltersCode = () => {
    return (
      <>
        <h4 className="border-bottom pb-2 mb-3">{t("frontend.product.filterBy")}</h4>
        {(config.searchCustomise?.showSearchBar ?? true) && <SearchBar redirectToSearchPage={false} />}
        {filters.map((filter) => (
          <div key={filter.slug}>
            <ProductListingModule.CoreComponents.FacetFilter
              config={config}
              filter={filter}
              facetKey={`facet_${filter.slug}`}
              updateAttribute={updateAttribute}
            />
          </div>
        ))}
      </>
    );
  };

  if (recordsCount < 1 && !isFetching) return null;

  return (
    <div className="col-lg-3 px-md-0">
      {isFetching && (
        <>
          <FilterLoader />
          <FilterLoader />
          <FilterLoader />
        </>
      )}

      {!isFetching && (
        <>
          {/* Mobile Code Starts */}
          <button
            className="nav-link filters-label collapsed align-items-end d-lg-none bg-transparent border-1 rounded"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#filterCollapse"
            aria-controls="filterCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>Filters</span> <i className="bi bi-toggles2"></i>
          </button>
          <div className="d-lg-none collapse navbar-collapse main-nav no-print" id="filterCollapse">
            <div className="mobile-nav">
              <div className="filter-block p-4">
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#filterCollapse"
                  aria-controls="filterCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="bi bi-x-circle"></i>
                </button>
                <FiltersCode />
              </div>
            </div>
          </div>
          {/* Mobile Code Ends */}
          <div className="filter-block p-4 d-lg-block d-none">
            <FiltersCode />
          </div>
        </>
      )}
    </div>
  );
};
